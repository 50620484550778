import React from "react"

import { Banner, Section, SaraPfostContact } from "../components/elements"
import styled from "styled-components"

import {
  Col,
  Color,
  Media,
  Wrapper,
  AlignCenter,
  ButtonB,
} from "../components/utilities"
import { Layout } from "../components/layouts/Layout"

import sara from "../images/sara.jpg"

const SpecialGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4rem;

  ${Media.below.tablet`
    text-align: center;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  `}

  img {
    margin-left: 4rem;
    border-radius: 5px;
    width: 75%;

    ${Media.below.tablet`
        margin-left: 0;
    `}
  }
`

const OrangeBar = styled.div`
  height: 3px;
  width: 40px;
  background-color: ${Color.orange};
  margin-bottom: 1rem;

  ${Media.below.tablet`
    display: none;
  `}
`

const ContactContainer = styled.div`
  display: inline-block;
  text-align: left;
`

const SaraPfost = ({ data }) => {
  return (
    <Layout title="Sara E. Pfost">
      <Banner title="Sara E. Pfost" />
      <Section>
        <Wrapper>
          <SpecialGrid>
            <Col>
              <h3>Sara E. Pfost</h3>
              <OrangeBar />
              <p>
                Sara E. Pfost established Pfost Law, Ltd. in 2020 and
                concentrates her practice in the areas of Estate Planning,
                Probate and Trust Administration, Real Estate Transactions, and
                Business Law. Ms. Pfost is licensed to practice law in both Ohio
                and Michigan and has been representing individuals, families,
                and businesses in both locations since 2006.
                <br />
                <br />
                Prior to establishing Pfost Law, Ltd., Ms. Pfost spent 14 years
                with Handwork, Kerscher &amp; Pfost, LLP, the last 8 of which
                were as a partner. During her time with HKP, Ms. Pfost’s
                attention to detail along with her client-centered philosophy
                gained her a large portfolio of clients with each client being a
                valued relationship. These relationships cross generations and
                have resulted in Ms. Pfost acting as a trusted advisor,
                executor, and trustee for many families.
                <br />
                <br />
                Ms. Pfost has had the privilege of speaking at business
                conventions and group meetings on topics including Estate
                Planning, Probate and Trust Administration, Real Estate
                Transactions and Business Law.
                <br />
                <br />
                Ms. Pfost resides in Perrysburg with her husband and two
                children.
                <br />
                <br />
                <h4>EDUCATION</h4>
                <ul>
                  <li>
                    Juris Doctor, University of Toledo College of Law (Cum
                    Laude); 2006
                  </li>
                  <li>
                    B.S. Business Administration with concentration in Pre-law,
                    Bowling Green State University (Summa Cum Laude); 2003
                  </li>
                </ul>
                <br />
                <h4>BAR ADMISSIONS</h4>
                <ul>
                  <li>Ohio, 2006</li>
                  <li>Michigan, 2007</li>
                </ul>
                <br />
                <h4>PROFESSIONAL ASSOCIATIONS</h4>
                <ul>
                  <li>Ohio State Bar Association</li>
                  <li>Toledo Bar Association</li>
                  <li>Wood County Bar Association</li>
                </ul>
                <br />
                <h4>COMMUNITY ASSOCIATIONS</h4>
                <ul>
                  <li>Junior League of Toledo, Past Member</li>
                </ul>
              </p>
            </Col>
            <Col>
              <img src={sara} />
              <br />
              <br />
              <AlignCenter>
                <ContactContainer>
                  <SaraPfostContact />
                </ContactContainer>
              </AlignCenter>
              <br />
              <br />
              <AlignCenter>
                <ButtonB
                  href={data.file.publicURL}
                  target="_blank"
                  modifiers={["primaryOutline"]}
                >
                  Download vCard
                </ButtonB>
              </AlignCenter>
            </Col>
          </SpecialGrid>
        </Wrapper>
      </Section>
    </Layout>
  )
}

export default SaraPfost

export const SaraPfostQuery = graphql`
  query SaraPfostQuery {
    file(relativePath: { eq: "Sara_Pfost.vcf" }) {
      publicURL
    }
  }
`
